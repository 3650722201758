import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const bubbleLink = document.createElement('link');
bubbleLink.setAttribute('rel', 'stylesheet')
bubbleLink.setAttribute('type', 'text/css');
bubbleLink.setAttribute('href', 'https://storage.googleapis.com/cdn_dokaa/css/main.54cabe7a.css')
document.head.appendChild(bubbleLink);

const bubbleContainer = document.createElement('div');
bubbleContainer.id = 'bubble-container';
document.body.appendChild(bubbleContainer);

const root = ReactDOM.createRoot(document.getElementById('bubble-container'));
root.render(<App />);